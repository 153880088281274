import React from 'react'
import Blog from '../components/Blog/Blog'
import LayoutBase from '../layouts/LayoutBase'

const BlogPage = () => {
    
    return (
        <LayoutBase>
            <main className="container">
                <h1>Blog</h1>
                <Blog />
            </main>
        </LayoutBase>
    )
}

export default BlogPage
